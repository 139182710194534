import React, { useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Button } from '../../components/button.component';
import { MenuLayout } from '../../components/menu-layout.component';
import { LoadingSpinner } from '../../components/loading.spinner.component';
import { ContactForm } from '../../components/contact-form.component';
import { Footer } from '../../components/footer.component';

import SFHousenkaLogo from '../../assets/corporations/sf-housenka.png';
import ScreenShotDesk from '../../assets/corporations/screenshot-desk.png';
import ScreenShotMobile from '../../assets/corporations/screenshot-mobile.png';

// Step svgs
import Step1 from '../../assets/svgs/pcr-test-steps/step1.svg';
import Step2 from '../../assets/svgs/pcr-test-steps/step2.svg';
import Step3 from '../../assets/svgs/pcr-test-steps/step3.svg';
import Step4 from '../../assets/svgs/pcr-test-steps/step4.svg';
import Step5 from '../../assets/svgs/pcr-test-steps/step5.svg';
import Feature9Fee from '../../assets/svgs/pcr-features/feature9-fee.svg';
import Feature7Group from '../../assets/svgs/pcr-features/feature7-group.svg';
import Feature8Hospital from '../../assets/svgs/pcr-features/feature8-hospital.svg';
import ShoppingCart from '../../assets/svgs/shoppingcart.svg';

export default function Home() {
  const { t, navigate } = useI18next();
  const [isLoading, setIsLoading] = useState(false);

  return <MenuLayout disableSecureLayout={true} isAbs={true} className="corporations transparent" corporations>
    <LoadingSpinner loading={isLoading} />
    <section className="corporations__hero">
        <div className="container">
          <div className="product-headings">
            <p className="t-sans product-headings__title3">
                
                <span className="t-orange product-headings__corporations-tag">法人・団体向け</span>
                <div className="sub">
                    <span className="heart-icon">
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4549 1.32524C15.5597 0.464589 14.3769 -0.00978525 13.151 0.00015306C11.7575 0.00293806 10.4218 0.57316 9.43651 1.58592L9.00466 2.03279L8.56979 1.58282C7.58459 0.572239 6.2503 0.00327285 4.85827 0.00015306C3.63239 -0.00978525 2.44964 0.464589 1.55444 1.32524C1.07297 1.79042 0.687329 2.35023 0.420408 2.97147C0.153487 3.59271 0.0107209 4.26271 0.000580935 4.94172C-0.00955899 5.62074 0.113134 6.29492 0.36138 6.92429C0.609625 7.55365 0.978365 8.12535 1.44572 8.60549L8.9956 16.3636L16.5455 8.60549C17.014 8.12659 17.3841 7.55587 17.6339 6.92719C17.8837 6.2985 18.008 5.62467 17.9996 4.94566C17.9911 4.26666 17.8501 3.59631 17.5847 2.9744C17.3194 2.35249 16.9352 1.79168 16.4549 1.32524Z" fill="#E0855E"/>
                        </svg>
                    </span>
                    <span className="t-green">安心で、働きやすい環境を</span>
                </div>
            </p>
            <h1 className="t-heading1 product-headings__title2">豊泉家PCR検査センター</h1>
            <p className="t-sans product-headings__title1 t-grey7">定期的に医療施設クオリティのPCR検査を。</p>
            <Button className="product-headings__button" href="#shopping-section">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.414382 1.43463L1.83982 2.28031L4.02036 11.6826C4.10038 12.0227 4.40045 12.2628 4.74054 12.2628H13.7428C14.1629 12.2628 14.5029 11.9227 14.5029 11.5026C14.5029 11.0825 14.1629 10.7424 13.7428 10.7424H5.34068L5.16064 9.98221H14.1229C14.543 9.96221 14.9231 9.66213 15.0031 9.24203L15.9833 4.02074C16.0633 3.68066 15.8433 3.34057 15.4832 3.26055C15.4432 3.26055 15.4032 3.24055 15.3632 3.24055L3.42021 2.50037L3.20016 1.56013C3.14014 1.34008 3.00011 1.16003 2.80006 1.06001L1.07455 0.0742983C0.694451 -0.105746 0.254343 0.0542933 0.0742983 0.414382C-0.105746 0.794476 0.0542933 1.25459 0.414382 1.43463ZM14.3229 4.70091L13.6227 8.50184H4.82056L3.8003 4.06075L14.3229 4.70091Z" fill="white"/>
                    <path d="M5.48029 15.6841C6.30892 15.6841 6.98066 15.0124 6.98066 14.1837C6.98066 13.3551 6.30892 12.6833 5.48029 12.6833C4.65166 12.6833 3.97992 13.3551 3.97992 14.1837C3.97992 15.0124 4.65166 15.6841 5.48029 15.6841Z" fill="white"/>
                    <path d="M12.2422 15.6841C13.0708 15.6841 13.7426 15.0124 13.7426 14.1837C13.7426 13.3551 13.0708 12.6833 12.2422 12.6833C11.4136 12.6833 10.7418 13.3551 10.7418 14.1837C10.7418 15.0124 11.4136 15.6841 12.2422 15.6841Z" fill="white"/>
                </svg>
                <span className="text">検査のお申し込み</span>
            </Button>
          </div>
          <div className="product-image">
          </div>
        </div>
    </section>
    <section className="corporations__features">
      <div className="container">
        <div className="features-block t-sans">
            <div className="feature">
              <div className="feature__icon"><Feature8Hospital /></div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">信頼いただける検査</h3>
                  <p className="feature__body t-p1 t-grey7">医療施設内の臨床検査室でPCR検査を行なっております。地方自治体でもご利用いただいている安全で質の高い検査です。</p>
              </div>
          </div>
          <div className="feature">
              <div className="feature__icon"><Feature9Fee /></div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">初期費用0円</h3>
                  <p className="feature__body t-p1 t-grey7">管理画面は即日発行。検査キットの料金以外、費用はかかりません。<br/>ウェブ管理で、どこからでもアクセスが可能です。</p>
              </div>
          </div>
          
          
          <div className="feature">
              <div className="feature__icon"><Feature7Group /></div>
              <div className="feature__detail">
                  <h3 className="feature__heading t-green">団体割引</h3>
                  <p className="feature__body t-p1 t-grey7">変異株にも対応しているPCR検査キットです。10回分以上まとめてお申し込いただくと、1検査あたり7,965円とお買い得です。</p>
              </div>
          </div>
      </div>
      </div>
    </section>
    <section className="corporations__whyus">
        <div className="container">
            <h2 className="t-h2 corporations__test-flow__heading t-center">選ばれるポイント</h2>
            <div className="stack">
                <div className="img-container">
                    <img src={ScreenShotDesk} alt="Desktop" />
                </div>
                <div className="t-grey9 corporations__whyus__copies">
                    <h3 className="t-serif t-h3">検査状況を一括管理</h3>
                    <p className="t-sans t-grey7 t-p1">社内の検査状況を、一括でリスト管理することが可能です。個人情報や検査時の症状などは、マイページで個々で登録したものが紐付けされますので、管理画面での難しい操作は必要ありません。</p>
                </div>
            </div>
            <div className="stack reverse">
                <div className="img-container">
                    <img src={ScreenShotMobile} alt="Mobile" />
                </div>
                <div className="t-grey9 corporations__whyus__copies">
                    <h3 className="t-serif t-h3">採取はどこでも簡単に</h3>
                    <p className="t-sans t-grey7 t-p1">採取される方には、個人用のマイページが用意されています。検査時の症状の入力や、採取方法の説明などは、全てウェブサイトで完結します。職場や自宅、どこにいても採取が可能です。</p>
                </div>
            </div>
        </div>
    </section>
    <section className="corporations__test-flow">
      <h2 className="t-h2 corporations__test-flow__heading">ご利用の流れ</h2>
      <div className="test-flow-container">
        <div className="test-flow test-flow--1">
          <div className="test-flow__image">
            <Step1 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">検査キットのお届け</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">検査キットを1〜2日で、まとめてお届けいたします</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--2">
          <div className="test-flow__image">
            <Step2 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">検査キットの登録</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">検査キット登録サイトで、個別で検査のご登録をいただきます</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--3">
          <div className="test-flow__image">
            <Step3 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">唾液の採取</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">オンラインでの手順に従って唾液を採取いただきます</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--4">
          <div className="test-flow__image">
            <Step4 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">梱包と返送</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">手順に従い梱包いただき、指定の方法で返送してください</p>
        </div>
        <div className="test-flow-divider"></div>
        <div className="test-flow test-flow--5">
          <div className="test-flow__image">
            <Step5 />
          </div>
          <p className="test-flow__title1 t-sans t-green t-bold">結果通知</p>
          <p className="test-flow__title2 t-p1 t-sans t-grey7">検体到着後、最短24時間で検査結果をメールで通知いたします</p>
        </div>
      </div>
    </section>
    <section className="corporations__application" id="shopping-section">
      <div className="container">
        <h2 className="t-h2 t-grey9 t-bold">PCR検査お申し込み</h2>
        <img className="main-product-image" src="/img/products/product-inside-corporation.png" />
        <div className="main-product">
          <h3 className="t-serif t-grey9">新型コロナウイルスPCR検査 10回分〜</h3>
          <p className="t-sans t-grey7 t-bold t-l5 main-product__price">¥7,965/1つあたり（税込）<span className="special-offer-tag t-sans t-orange t-bold">{t('free shipping')}</span></p>
          <p className="t-sans t-grey7 t-p1">簡単・安心・安全なPCR検査キットを用いた、自宅での唾液採取検査となります。返送も手軽にでき、同封しているレターパックで近くのポストから投函するだけです。検査結果は検体到着後72時間以内にメールでご報告させていただきます。<br/>数量の箇所に10回分以上をいれていただくことで、割引となります。また、管理画面をご希望の場合は、オプションサービスよりお申し込みください。</p>
          <div className="corporations__application__btn-container">
            <Button className="shopping-cart" href="https://sf-housenka.org/ec/2021/04/01/pcr-testing/" target="_blank"><ShoppingCart/> 検査のお申し込み</Button>
          </div>
        </div>
      </div>
    </section>
    <section className="corporations__additional">
      <div className="container">
        <h3 className="t-heading2 t-serif t-grey9 t-bold corporations__additional__heading">その他のサービス</h3>
        <div className="additional-products">
          <div className="additional-product">
            <h4 className="t-product-name t-serif additional-product__name">法人用管理画面</h4>
            <p className="t-sans t-grey7 additional-product__price">¥0 <span className="spacer"></span><span className="special-offer-tag t-sans t-orange t-bold">オプション</span></p>
            <p className="t-sans t-grey7 additional-product__description t-p1">検査状況を一括管理できる、管理画面をご希望の場合は、こちらからお申し込みください。社内の検査状況の把握や結果の一括エクスポートなどができる管理画面を発行し、速やかにご案内させていただきます。</p>
            <Button className="shopping-cart option" href="https://sf-housenka.org/ec/2021/05/01/pcr-option-3/" target="_blank"><ShoppingCart/> カートにいれる</Button>
          </div>
          <div className="additional-product">
            <h4 className="t-product-name t-serif additional-product__name">国内向け陰性証明書発行</h4>
            <p className="t-sans t-grey7 additional-product__price">+ ¥5,500〜 （税込）<span className="special-offer-tag t-sans t-orange t-bold">オプション</span></p>
            <p className="t-sans t-grey7 additional-product__description t-p1">陰性証明書発行を希望される方への追加サービスとなります。陰性を確認後、グループ医療機関の医師がPDFファイルで陰性証明書（日本語版・英語版）を発行いたします。</p>
            <Button className="shopping-cart option" href="https://sf-housenka.org/ec/2021/04/01/pcr-option-2/" target="_blank"><ShoppingCart/> カートにいれる</Button>
          </div>
        </div>
      </div>
    </section>
    <section className="corporations__faq">
      <div className="container">
        <h2 className="t-h2 corporations__faq__heading">よくある質問</h2>
        <div className="faqs">
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">定期的にスクリーニング検査を受けたいのですが？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">毎月指定日に検査キットの配送をさせていただきます。</p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">職場に濃厚接触者が出てすぐにでも検査したいのですが、最短でどの位で受けることができますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">
            検査キットのお申し込みは即日可能です（検体キットを取りに来ていただく事が条件になります。大阪府豊中市中桜塚3-2-1まで）。その後、検体採取後、北大阪ほうせんか病院（大阪府茨木市室山1-2-1）までお持ち込みいただくことが最短での検査になります。<br/>
            ウェブでのお申し込みの場合、午前中のお申し込みは当日発送いたします。検査キット到着後、すぐに検体の採取・ポスト投函をしていただいた場合、検体を受領してから24時間以内に結果がわかります（クイックお申し込みの場合）。
            </p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">企業担当者で結果を管理したいのですが？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">管理画面上で登録状況～結果まで把握することが可能です。</p>
          </div>
          <div className="faq-card">
            <p className="faq-card__question t-sans t-grey9">土日や祝日も発送や検査をしていますか？</p>
            <p className="faq-card__answer t-p1 t-sans t-grey7">日曜日と祝日は発送や検査は行なっておりません。検査キットの発送は、月～土曜日に行なっております。返送された検体のPCR検査は、月～金曜日に行っております。</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button to="/corporations/faqs">もっと見る</Button>
          </div>
        </div>
      </div>
    </section>

    <section className="corporations__operation">
        <div className="container">
            <h2 className="t-h2">運営会社について</h2>
            <div className="corporations__operation__detail">
                <div className="corporations__operation__detail__logo">
                    <img src={SFHousenkaLogo} alt="Company Logo" />
                </div>
                <div className="corporations__operation__detail__copies t-sans t-grey7">
                    <p className="t-p1">当センターは、1995年から大阪府北摂エリアを中心に予防・医療・介護・生活支援の事業を展開している豊泉家グループ 一般財団法人SF豊泉家が運営しております。2020年10月より「豊泉家PCR検査センター」を立ち上げ、開設からこれまで80,000件を超える検査を行ってまいりました（2023年5月時点）。 <br/>
                    併せまして2021年2月に、民間病院では最大規模の新型コロナウイルス専用病棟「ほうせんか新型コロナウイルス感染症病棟（HOUSENKA COVID-19 UNIT）を開設いたしました。さらに、2021年11月には全国で初となる、病院が宿泊療養施設を運営する「病院一体運営型宿泊療養施設」を開設いたしました。2022年7月には要介護者専用の病棟として「大阪コロナ高齢者医療介護臨時センター・ほうせんか」を開設し、地域医療への貢献を果たしてまいります。</p>
                    <p className="t-p1">
                        医療機関の情報<br/>
                        <span>
                            <svg className="spacer" width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0H7V7H0V0Z" fill="#59697A"/>
                            </svg>
                            施設コード：2714207483
                        </span><br/>
                        <span>
                            <svg className="spacer" width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0H7V7H0V0Z" fill="#59697A"/>
                            </svg>
                            医療機関名：北大阪ほうせんか病院
                        </span><br/>
                        <span>
                            <svg className="spacer" width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0H7V7H0V0Z" fill="#59697A"/>
                            </svg>
                            住所：大阪府茨木市室山1丁目2番2号
                        </span><br/>
                        
                        

                    </p>
                </div>
            </div>
        </div>
    </section>

    <section className="corporations__contact" id="contact-us">
      <div className="container">
        <div className="contact-bg">
          <h2 className="t-heading2 t-serif t-bold">{t('Contact Us')}</h2>
        </div>
        <h2 className="t-heading2 t-serif t-bold">{t('Contact Us')}</h2>
        <ContactForm />
      </div>
    </section>
    <Footer />
  </MenuLayout>;
}
